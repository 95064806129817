import React from 'react';
import styled from 'styled-components';
import { theme } from '../../helpers/GlobalStyles';


const ButtonDiv = styled.div`
  display: inline-block;
  height: 2em;
  margin: 0 0.5em;
  padding: 0 3em;

  border: 1px solid ${theme.colors.mediumGray};
  color: ${theme.colors.offWhite};
  background-color: ${theme.colors.lightGray};

  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  line-height: 2em;

  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
`;

/* eslint-disable no-empty-function */
const Button = ({ buttonName = 'Click Me', buttonClick = () => {}, isDisabled = false, className }) => {
/* eslint-enable no-empty-function */

  return (
    <ButtonDiv className={className} onClick={buttonClick} disabled={isDisabled ? 'disabled' : false}>{buttonName}</ButtonDiv>
  );
};

export default Button;

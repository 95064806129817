import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faIdCard } from '@fortawesome/free-solid-svg-icons';

import { ShellContext } from '../../context';

import SearchInput from './SearchInput';

const ResultsContainer = styled.div`
  margin: 2em ${constants.DETAIL_MARGIN}
`;

const TableSearchContainer = styled.div`
    text-align: center;
`;

const SearchWrapper = styled.div`
    position: relative;
    margin: 2em auto;
    width: fit-content;
`;

const ResultsWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-height: calc(100% - 20em);
  overflow-y: auto;
  overflow-x: hidden;
`;

const ResultsTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;
`;

const ResultsTableHead = styled.thead`
`;

const ResultsTableBody = styled.tbody`
`;

const ResultsTableHeaderRow = styled.tr`
`;

const ResultsTableHeaders = styled.th`
    text-align: left;
    font-weight: ${theme.fontWeight.bold};
    font-family: ${theme.fontFamily.black};
    font-size: ${theme.fontSize.large};
    color: ${theme.colors.darkGray};
    padding-bottom: 1em;
`;

const ResultsTableBodyRow = styled.tr`
    &:hover {
        background-color: ${theme.colors.veryLightGray};
        cursor: pointer;
    }

    &[selected=true] {
        background-color: ${theme.colors.offWhite};
    }

    border-bottom:1px solid ${theme.colors.veryLightGray};
`;

const ResultsTableBodyCell = styled.td`
    text-align: left;
    padding: 10px 0;
    font-size: ${theme.fontSize.large};
    ${({ isButton }) => isButton && 'cursor: pointer; position: relative;'};
    ${({ alignment }) => `text-align: ${alignment}`}
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  bottom: 5px;
`;

const UserIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const AccountIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

const Search = () => {

  const { dataset, pbData, setCartData } = useContext(ShellContext);
  const [tableSearchResults, setTableSearchResults] = useState(dataset);

  const onRowClick = (event, c) => {
      let target = event.target;

      if (target.tagName !== 'tr') {
        target = target.parentNode;
      }

      //  console.log(target);
      console.log(c);

      //  If currently selected, clear, otherwise select the row.
      if (pbData === c) {
        setCartData(null);
      } else {
        setCartData(c);
      }
  };

  const clearRowSelection = () => {
    let rows = document.querySelectorAll('tr');
    rows.forEach((row) => {
      row.removeAttribute('selected');
    });
  };

  const onSearch = (event) => {
      let value = event.target.value.toLowerCase();
      let results = dataset.filter(c => {
        let orderNumber = c.productBrowseData.orderNumber;

        if (orderNumber.toLowerCase().indexOf(value) > -1) {
          return true;
        }
        return false;
      });

      //  Clear any selection if the search doesn't include that record.
      if (pbData) {
        let found = results.some(c => {
          return c.productBrowseData.orderNumber === pbData.productBrowseData.orderNumber;
        });

        if (!found) {
          setPBData(null);
        }
      }

      setTableSearchResults(results);
  };

  // respond to changes in query field state
  useEffect(() => {
    //  TODO: if selected row isn't in the filter do we de-select it?
    setTableSearchResults(dataset);
  }, [dataset]);


  //  adjust table row selection to current cvData value
  useEffect(() => {
    if (!pbData || !pbData.productBrowseData.orderNumber) {
      return clearRowSelection();
    }

    let rows = document.querySelectorAll('tr');
    rows.forEach((row) => {
      if (row.getAttribute('id') === 'rtr-' + pbData.productBrowseData.orderNumber) {
        row.setAttribute('selected', true);
      } else {
        row.removeAttribute('selected');
      }
    });
  }, [pbData]);


  return (
    <ResultsContainer>
      <TableSearchContainer>

        <SearchWrapper>
          <SearchInput onSearch={(event) => onSearch(event)}></SearchInput>
          <SearchIcon size="xs" icon={ faSearch } />
        </SearchWrapper>

        <ResultsWrapper>
          <ResultsTable>
            <ResultsTableHead>
              <ResultsTableHeaderRow>
                  {constants.SEARCH_TABLE_HEADERS.map(c => {
                      return <ResultsTableHeaders key={c}>{c}</ResultsTableHeaders>;
                  })}
                  <ResultsTableHeaders></ResultsTableHeaders>
              </ResultsTableHeaderRow>
            </ResultsTableHead>
            <ResultsTableBody>
              {tableSearchResults.map(c => {
                  return (
                    <ResultsTableBodyRow id={'rtr-' + c.productBrowseData.orderNumber} key={c.productBrowseData.orderNumber} onClick={(event) => onRowClick(event, c)}>
                      <ResultsTableBodyCell><UserIcon icon={faUser} />{c.productBrowseData.orderNumber}</ResultsTableBodyCell>
					  <ResultsTableBodyCell>{c.productBrowseData.cartNumber}</ResultsTableBodyCell>
                      <ResultsTableBodyCell>{c.productBrowseData.transactionIndicator}</ResultsTableBodyCell>
                      <ResultsTableBodyCell>{c.productBrowseData.carryOutTransaction+""}</ResultsTableBodyCell>
                    </ResultsTableBodyRow>
                  );
              })}
            </ResultsTableBody>
          </ResultsTable>
        </ResultsWrapper>
      </TableSearchContainer>

    </ResultsContainer>
  );
};

export default Search;

import React from 'react';
import styled from 'styled-components';

import ShellFooterButtons from './ShellFooterButtons';


const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
`;


const ShellFooter = () => {
  return (
    <FooterContent>
      <ShellFooterButtons/>
    </FooterContent>
  );
};

export default ShellFooter;

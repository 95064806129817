import React from 'react';
import styled from 'styled-components';
import { theme } from '../../helpers/GlobalStyles';

const InputComponent = styled.input`
    border-width: 0 0 1px 0;
    outline: 0;
    border-color: ${theme.colors.lightGray};
    font-size: ${theme.fontSize.large};
    width: 300px;
    padding:3px;
`;

const SearchInput = ({ onSearch }) => {

    return (
        <InputComponent
            onInput={(event) => onSearch(event)}
            placeholder='search'
        ></InputComponent>
    );
};

export default SearchInput;

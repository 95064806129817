import React from 'react';
import styled from 'styled-components';
import { theme } from '../../helpers/GlobalStyles';

import ShellFooter from './ShellFooter';

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 6em;
  width: 100%;
  border-top: 1px solid ${theme.colors.darkGray};
`;

const FooterDiv = styled.div`
  margin: 1em 5%;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterDiv>
        <ShellFooter/>
      </FooterDiv>
    </FooterContainer>
  );
};

export default Footer;

import React from 'react';
import styled from 'styled-components';
import * as constants from '../../constants/Constants';

import ShellHeaderButtons from './ShellHeaderButtons';


const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ShellTitle = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;


const ShellHeader = ({ title = constants.SHELL_HEADER }) => {

  return (
    <HeaderContent>
      <ShellTitle>{title}</ShellTitle>
      <ShellHeaderButtons/>
    </HeaderContent>
  );
};

export default ShellHeader;

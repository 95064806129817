import React, { useState, useEffect } from 'react';
import IndexGlobalStyle, { Theme } from './helpers/GlobalStyles';
import Header from './components/Header/Header';
import Details from './components/Details/Details';
import Footer from './components/Footer/Footer';

import ShellContext from './context';

import database from './database';

/* global comGkSoftwareGkrAppEnablementApi */

const App = function() {

  const [pbData, setCartData] = useState();
  const [dataset, setDataset] = useState([]);
  const [searchQuery, setSearchQuery] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [modalActive, setModalActive] = useState(false);

  const gkPos = new comGkSoftwareGkrAppEnablementApi.Pos_CST();

  const gkCommon = new comGkSoftwareGkrAppEnablementApi.Common();

  //  resolve dataset via mock data server or static data
  useEffect(() => {
    //  TODO: try to fetch from server first
    setDataset(database);
  }, []);

  const shellData = {
    pbData,
    setCartData,
    dataset,
    setDataset,
    searchQuery,
    setSearchQuery,
    searchResults,
    setSearchResults,
    modalActive,
    setModalActive,
    gkPos,
    gkCommon
  };

  return (
    <ShellContext.Provider value={shellData}>
        <IndexGlobalStyle/>
        <Theme>
          <Header/>
          <Details/>
          <Footer/>
        </Theme>
    </ShellContext.Provider>
  );
};

export default App;

//  Strings
export const SHELL_HEADER = 'PB-GK SHELL';

export const FETCH_POS_DATA = 'FETCH POS DATA';
export const PUSH_CART_DATA = 'SEND CART';
export const CLOSE_BROWSER = 'CLOSE BROWSER';
export const GET_NON_EXISTING_ITEMS = 'GET NON EXISTING ITEMS';

export const SEARCH_TABLE_HEADERS = [
  'Order Number',
  'Cart Number',
  'Transaction Indicator',
  'Carry Out'
];

//  Layout
export const DETAIL_MARGIN = '90px';
export const TEXTAREA_DEFAULT_HEIGHT = '50';

// Events
export const ENTER_KEY_CODE = 13;
export const TAB_KEY_CODE = 9;
export const ESCAPE_KEY_CODE = 27;
export const UP_ARROW_KEY_CODE = 38;
export const DOWN_ARROW_KEY_CODE = 40;
export const MOUSEDOWN = 'mousedown';
export const KEYDOWN = 'keydown';


import record1 from './record1.json';
import record2 from './record2.json';
import record3 from './record3.json';
import record4 from './record4.json';
import record5 from './record5.json';
import record6 from './record6.json';
import record7 from './record7.json';
import record8 from './record8.json';
import record9 from './record9.json';
import record10 from './record10.json';
import record11 from './record11.json';
import record12 from './record12.json';
import record13 from './record13.json';
import record14 from './record14.json';
import record15 from './record15.json';
import record16 from './record16.json';
import record17 from './record17.json';
import record18 from './record18.json';
import record19 from './record19.json';
import record20 from './record20.json';
import record21 from './record21.json';
import record22 from './record22.json';
import record23 from './record23.json';
import record24 from './record24.json';
import record25 from './record25.json';
import record26 from './record26.json';
import record27 from './record27.json';
import record28 from './record28.json';
import record29 from './record29.json';
import record30 from './record30.json';
import record31 from './record31.json';
import record32 from './record32.json';
import record33 from './record33.json';
import record34 from './record34.json';
import record35 from './record35.json';
import record36 from './record36.json';
import record37 from './record37.json';
import record38 from './record38.json';
import record39 from './record39.json';
import record40 from './record40.json';
import record41 from './record41.json';
import record42 from './record42.json';
import record43 from './record43.json';
import record44 from './record44.json';
import record45 from './record45.json';
import record46 from './record46.json';
import record47 from './record47.json';

export const database = [
  record1,
  record2,
  record3,
  record4,
  record5,
  record6,
  record7,
  record8,
  record9,
  record10,
  record11,
  record12,
  record13,
  record14,
  record15,
  record16,
  record17,
  record18,
  record19,
  record20,
  record21,
  record22,
  record23,
  record24,
  record25,
  record26,
  record27,
  record28,
  record29,
  record30,
  record31,
  record32,
  record33,
  record34,
  record35,
  record36,
  record37,
  record38,
  record39,
  record40,
  record41,
  record42,
  record43,
  record44,
  record45,
  record46,
  record47
];

export default database;

import React, { useContext } from 'react';
import styled from 'styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';

import ShellContext from '../../context';

import Button from '../Button/Button';


const ButtonSet = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const FooterButton = styled(Button)`

    background: ${theme.colors.darkGray};

    &[disabled] {
      background: transparent;
      cursor: inherit;
    }
`;


const isEmpty = (obj) => {
  return obj === null || obj === undefined || obj.length === 0;
};


  window.handleCartPushOk = function(){
	alert('Cart Updated');
  };

  window.handleCartPushFailed = function(e){
    alert('Cart Update Failed: ' + e);
  };

  window.handleCloseBrowserOk = function(){
    console.log('Closing Browser');
  };

  window.handleCloseBrowserFailed = function(e){
    alert('Closing Browser Failed: ' + e);
  };
  
  window.handleGetNonExistingItemsOK = function (context) {
    alert('Get Non Existing Items OK');
  };

  window.handleGetNonExistingItemsFailed = function (e) {
    alert('Get Non Existing Items Failed: ' + e);
  };
  
const ShellFooterButtons = () => {

  const { pbData, setCartData, gkPos } = useContext(ShellContext);

  /* eslint-disable no-unused-vars */
  /* eslint-enable no-unused-vars */

  const pushCartToPOS = () => {
    if (isEmpty(pbData)) {
      return;
    }

    //  TODO: use modal rather than alert
    if (confirm('Send Cart To Selling Platform?')) {
	  var request = gkPos.createPushProductBrowseCartRequest(pbData);
	  console.log(request);		  
      gkPos.pushProductBrowseCart(
        'handleCartPushOk',
        'handleCartPushFailed',
        request
      );
      setPBData(null);
    }
  };
  
  const closeBrowser = () => {
	gkPos.closeBrowser(
	  'handleCloseBrowserOk',
	  'handleCloseBrowserFailed'
	);  
  };

  const getNonExistingItems = () => {
    const doIt = confirm('Get Non Existing Item in Selling Platform?');
	
    if (doIt) {
		var request = gkPos.createPushProductBrowseCartRequest(pbData);
        console.log(gkPos.getNonExistingItemsFromRequest(
          'handleGetNonExistingItemsOK',
          'handleGetNonExistingItemsFailed',
		  request
        ));
    }
  };
  
  return (
    <ButtonSet>
        <FooterButton buttonName={constants.PUSH_CART_DATA} buttonClick={pushCartToPOS} isDisabled={isEmpty(pbData)}/>
		<FooterButton buttonName={constants.GET_NON_EXISTING_ITEMS} buttonClick={getNonExistingItems} isDisabled={isEmpty(pbData)}/>
		<FooterButton buttonName={constants.CLOSE_BROWSER} buttonClick={closeBrowser} />
    </ButtonSet>
  );
};

export default ShellFooterButtons;

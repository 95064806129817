import React from 'react';

import Search from '../Search/Search';

const Details = () => {
  return (
    <Search/>
  );
};

export default Details;

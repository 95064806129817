import React from 'react';
import styled from 'styled-components';
import { theme } from '../../helpers/GlobalStyles';

import ShellHeader from './ShellHeader';

const HeaderContainer = styled.div`
  padding-top: 0px;
`;

const HeaderDiv = styled.div`
  background-color: ${theme.colors.banana};
  color: ${theme.colors.white};
  padding: 1% 5%;
  font-family: ${theme.fontFamily.normal};
`;

const Header = () => {

  return (
    <HeaderContainer>
      <HeaderDiv>
        <ShellHeader/>
      </HeaderDiv>
    </HeaderContainer>
  );
};

export default Header;

import React from 'react';
import { ThemeProvider } from 'styled-components';

export const theme = {
  colors: {
    darkGray: '#333333',
    banana: '#EABD0E',
    primaryRed: '#ED1C24',
    lightGray: '#666666',
    veryLightGray: '#E6E6E6',
    mediumGray: '#999999',
    offWhite: '#CCCCCC',
    white: '#FFFFFF',
    lightBlue: '#4C8BF5',
    green: '#3C832C'
  },
  fontFamily: {
    normal: 'Lato Regular',
    bold: 'Lato Bold',
    black: 'Lato Black'
  },
  fontWeight: {
      bold: 'bold'
  },
  fontSize: {
    xSmall: '8px',
    small: '10px',
    medium: '12px',
    large: '14px',
    xLarge: '16px'
  }
};

export const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

const IndexGlobalStyle = () => {
  return (
    <style jsx="true" global="true">
      {`
        @font-face {
          font-family: "Lato Black";
          src: url('fonts/Lato-Black.ttf');
        }

        @font-face {
          font-family: "Lato Bold";
          src: url('fonts/Lato-Bold.ttf');
        }

        @font-face {
          font-family: "Lato Regular";
          src: url('fonts/Lato-Regular.ttf');
        }

        html {
          height: 100%;
        }

        body {
          margin: 0;
          font-family: "Lato Regular";
          height: 100%;
        }

        #__next {
          height: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
        }

        // SCROLL BAR STYLING
        /* width */
        ::-webkit-scrollbar {
          width: 14px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          /* background: #f1f1f1; */
          border-left: 1px solid #999999;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #bbbbbb;
          border-radius: 7px;
          border: 2px solid transparent;
          background-clip: content-box;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #999999;
          border: 2px solid transparent;
          background-clip: content-box;
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }
      `}
    </style>
  );
};

export default IndexGlobalStyle;

import React, { useContext } from 'react';
import styled from 'styled-components';
import * as constants from '../../constants/Constants';

import Button from '../Button/Button';

import { ShellContext } from '../../context';

// Exists primarily to group button pair.
const ButtonSet = styled.div`
`;


window.handleFetchPOSDataOK  = function(context){
  alert('POS Data Fetched');
};

window.handleFetchPOSDataFailed = function(err){
  alert('POS Data Fetch Failed');
};

const ShellHeaderButtons = () => {

  const { gkPos } = useContext(ShellContext);

  const fetchPOSData = () => {
    const doIt = confirm('Fetch Data From Selling Platform?');
    if (doIt) {
        gkPos.fetchPOSData(
          'handleFetchPOSDataOK',
          'handleFetchPOSDataFailed'
        );
    }
  };
  

  return (
    <ButtonSet>
		<Button buttonName={constants.GET_NON_EXISTING_ITEMS} buttonClick={fetchPOSData}/>
    </ButtonSet>
  );
};

export default ShellHeaderButtons;
